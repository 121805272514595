//~ Import modules
import './ThemeBtn.scss';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../../Store/Reducers/Theme';
import { ReactComponent as Sun } from './sun.svg';
import { ReactComponent as Moon } from './moon.svg';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const ThemeBtn = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const [switcher, setSwitcher] = useState('');

  const dispatch = useDispatch();
  const changeTheme = () => {
    setSwitcher('active');
    dispatch(toggleTheme());
  };

  useEffect(() => {
    setTimeout(() => setSwitcher(''), 210);
  });

  return (
    <div className={`theme-btn theme--${theme}`} role="button">
      <span onClick={changeTheme} className={`theme-btn__circle ${switcher} theme--${theme}`}>
        {theme === 'light' ? <Moon /> : <Sun />}
      </span>
    </div>
  );
};

export default ThemeBtn;