//~ Import modules
import mediaReducer from './Media';
import cursorReducer from './Cursor';
import themeReducer from './Theme';
import apiYumelio from '../API';

const reducers = {
    theme: themeReducer,
    media: mediaReducer,
    cursor: cursorReducer,
    apiYumelio
};

//export all combined reducers to use in the store
export default reducers;