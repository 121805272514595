//~ Import modules
import './Box.scss';
import { motion } from 'framer-motion';

//~ Component
const Box = () => {
  return (
    <motion.div className="box" whileInView={{ translateX: '100px', transition: { duration: 1 } }}>
      <h1>Box</h1>
    </motion.div>
  );
};

export default Box;

//! Component test
