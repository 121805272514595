//~ Import module
import './Error404.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as ErrorSVG } from './404.svg';
import { State } from '../../../Types/GlobalTypes';

const Error404 = () => {
  const theme = useSelector((state: State) => state.theme?.default);

  return <ErrorSVG className={`error__svg theme--${theme}`} />;
};

export default Error404;
