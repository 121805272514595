//~ Import module
import './Gingko.scss';
import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion, useMotionValue, useTransform, useInView } from 'framer-motion';
import { ReactComponent as GingkoSVG } from './Gingko.svg';
import { State, Section } from '../../../Types/GlobalTypes';

//~ Component
const Gingko = ({ section }: Section) => {
  const theme = useSelector((state: State) => state.theme?.default);
  const [animation, setAnimation] = useState('');

  const ref = useRef(null);
  const isInView = useInView(ref);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const rotateX = useTransform(y, [-100, 100], [60, -60]);
  const rotateY = useTransform(x, [-100, 100], [-60, 60]);

  useEffect(() => {
    setAnimation(isInView ? 'gingko__svg--animation' : '');
  }, [isInView]);

  return (
    <motion.div className={`gingko__container gingko__${section}`}>
      <motion.div
        className="gingko"
        style={{
          x: x,
          y: y,
          rotateX,
          rotateY,
        }}
        drag
        dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
        dragElastic={0.6}
        whileTap={{ cursor: 'grabbing' }}
      >
        <GingkoSVG className={`gingko__svg theme--${theme} ${animation}`} ref={ref} />
      </motion.div>
    </motion.div>
  );
};

export default Gingko;
