//~ Import modules
import './Circle.scss';
import { ReactComponent as CircleTextured } from './circle-textured.svg';
import { CircleProps } from './Types';

//~ Component
const Circle = ({ property, section }: CircleProps) => {
  return (
    <div className={`circle-draw circle-draw__${section} circle-draw--${property} `} data-parallax-axis="0.008">
      {property === 'textured' && <CircleTextured />}
    </div>
  );
};

export default Circle;
