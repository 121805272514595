//~ Import module
import './ArrowUp.scss';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ReactComponent as ArrowUpSVG } from './ArrowUp.svg';
import { State } from '../../../Types/GlobalTypes';
import { ScrollYProps } from '../../../Types/GlobalTypes/FramerMotionTypes';

//~ Component
const ArrowUp = ({ scrollYProgress }: ScrollYProps) => {
  const theme = useSelector((state: State) => state.theme?.default);
  const defaultMediaScreen = useSelector((state: State) => state.media?.defaultMediaScreen);

  const scrollToTop = (event: Event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <motion.div className="arrow-up noselect" style={{ opacity: scrollYProgress }}>
      <ArrowUpSVG onClick={scrollToTop} className={`arrow-up__svg theme--${theme}`} />
      {defaultMediaScreen === 'desktop' && <p className={`arrow-up__title theme--${theme}`}>SCROLL TO TOP</p>}
    </motion.div>
  );
};

export default ArrowUp;
