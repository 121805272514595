//~ Import modules
import './Pattern.scss';
import { useRef } from 'react';
import { useInView } from 'framer-motion';
import { PatternProps } from './Types';

//~ Components
const Pattern = ({ property, section }: PatternProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const animation = isInView ? `pattern__${section}--animation` : '';

  return <div ref={ref} className={`pattern pattern__${section} pattern--${property} ${animation} `}></div>;
};

export default Pattern;
