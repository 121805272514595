//~ Import module
import './Style/main.scss';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion, useInView } from 'framer-motion';
import { ANIMATION_SCALE_UP } from 'Utils/Animations/FramerMotion';
import * as imageSVG from './logos';
import { Category, ButtonWrapper } from '../../Components';
import { State, Section } from '../../../Types/GlobalTypes';
import { ICardProject } from './Types';

//~ Component
const ProjectCard = ({ card, index }: any) => {
  const theme = useSelector((state: State) => state.theme?.default);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const animation = isInView ? 'project-card__article__main-title__title--animation' : '';
  const [reverse, setReverse] = useState(false);
  const isEven = (index: number) => index % 2 === 0;

  useEffect(() => {
    isEven(index) && setReverse(true);
  }, [index]);

  const categories = card.categories;
  const paragraphs = card.paragraphs;

  // use a type assertion to tell TypeScript to ignore the inferred type and treat the variable as a specific type
  // we used the "keyof" operator to get the type of the keys of the object, which TypeScript can infer correctly. We then used a type assertion to tell TypeScript to treat "card.name" as a key of the object, which prevents the error
  const imgSource = imageSVG[card.name as keyof typeof imageSVG];

  return (
    <section ref={ref} className={`project-card__container wrapper-parent ${reverse ? 'project-card__container--reverse' : ''} theme--${theme}`}>
      <div className={`project-card__image noselect ${reverse ? 'project-card__image--reverse' : ''}`}>
        <div className={`project-card__image__circle`}>
          <div className={`project-card__image__background ${reverse ? 'project-card__image__background--reverse' : ''}`}>
            <motion.div className={`project-card__image__tablet ${reverse ? 'project-card__image__tablet--reverse' : ''}`} {...ANIMATION_SCALE_UP}>
              <img className={`project-card__image__logo`} src={`${imgSource}`} alt={`badge ${card.name}`} />
            </motion.div>
            <div className={`project-card__image__categories ${reverse ? 'project-card__image__categories--reverse' : ''}`}>
              {categories?.map((category: any) => (
                <Category key={uuidv4()} category={category} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <article className={`project-card__article ${reverse ? 'project-card__container--reverse' : ''}`}>
        <div className={`project-card__article__content ${reverse ? 'project-card__article__content--reverse' : ''} `}>
          {paragraphs?.map((paragraph: any) => (
            <p key={uuidv4()} className={`project-card__article__content__text ${reverse ? 'project-card__article__content__text--reverse' : ''}`}>
              {paragraph.text}
            </p>
          ))}

          <a className={`project-card__article__content__link`} href={card.link} target="_blank">
            <ButtonWrapper />
          </a>
        </div>
        <div className={`project-card__article__main-title ${reverse ? 'project-card__article__main-title--reverse' : ''}`}>
          <p className={`project-card__article__main-title__number ${reverse ? 'project-card__article__main-title__number--reverse' : ''}`}>
            0{index}
          </p>
          <p className={`project-card__article__main-title__title ${animation}`}>{card.title}</p>
        </div>
      </article>
    </section>
  );
};

export default ProjectCard;
