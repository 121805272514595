//~ Import modules
import './Style/main.scss';
import { useRef, useEffect, useState } from 'react';
import { useInView } from 'framer-motion';
import { Section } from '../../../Types/GlobalTypes';
import { ReactComponent as ElephantSVG } from './elephant.svg';

const Elephant = ({ section }: Section) => {
  const [animation, setAnimation] = useState('');
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    setAnimation(isInView ? 'elephant--animation' : '');
  }, [isInView]);

  return <ElephantSVG className={`elephant ${animation} elephant__${section}`} ref={ref} />;
};

export default Elephant;
