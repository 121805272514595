//~ Import modules
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, useInView } from 'framer-motion';
import { ANIMATION_TRANSLATE_FROM_RIGHT_INVIEW } from 'Utils/Animations/FramerMotion';
import Fragments from './Fragments';
import { SliderContentProps } from './Types';

//~ Component
const SliderContent = ({ activeIndex, sliderText }: SliderContentProps) => {
  const ref = useRef(null);
  // If the ref is in view
  const isInView = useInView(ref);

  return (
    <section ref={ref} className={`slider__slides`}>
      {sliderText.map((slide: any, index: any) => (
        <div key={uuidv4()} className={`slider__slide--${index === activeIndex ? 'active' : 'inactive'}`}>
          <motion.div className={`slider__description`}>
            <h2 className="slider__slide-title">{slide.title.toUpperCase()}</h2>
            <article className="slider__slide-content">
              <p className="slider__slide-description">{slide.description}</p>
              <p className="slider__slide-text">{slide.content}</p>
            </article>
          </motion.div>

          <motion.div className={`slider__fragment noslect`} {...ANIMATION_TRANSLATE_FROM_RIGHT_INVIEW}>
            <Fragments section={slide.section} isInView={isInView} />
          </motion.div>
        </div>
      ))}
    </section>
  );
};

export default SliderContent;
