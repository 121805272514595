//~ Import modules
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { ReactComponent as Back } from './SVG/frag-back.svg';
import { ReactComponent as Devops } from './SVG/frag-devops.svg';
import { ReactComponent as Front } from './SVG/frag-front.svg';
import { ReactComponent as Design } from './SVG/frag-design.svg';
import { FragProps } from './Types';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Fragments = ({ section, isInView }: FragProps) => {
  const theme = useSelector((state: State) => state.theme?.default);

  const fragments = [
    {
      name: 'back',
      component: <Back key={uuidv4()} className={`slider__back ${isInView ? 'slider--animation' : ''} theme--${theme}`} />,
    },
    {
      name: 'devops',
      component: <Devops key={uuidv4()} className={`slider__devops ${isInView ? 'slider--animation' : ''} theme--${theme}`} />,
    },
    {
      name: 'front',
      component: <Front key={uuidv4()} className={`slider__front ${isInView ? 'slider--animation' : ''} theme--${theme}`} />,
    },
    {
      name: 'design',
      component: <Design key={uuidv4()} className={`slider__design ${isInView ? 'slider--animation' : ''} theme--${theme}`} />,
    },
  ];

  return <>{fragments.map((elem) => section === elem.name && elem.component)}</>;
};

export default Fragments;
