//~ Import module
import './Sidebar.scss';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-scroll';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Router } from '../../Routes/Routes';
import { SIDEBAR_OPTIONS } from 'Utils/Animations/ReactScroll';
import { ANIMATION_TRANSLATE_FROM_TOP } from 'Utils/Animations/FramerMotion';
import { State, Section } from '../../../Types/GlobalTypes';

//~ Component
const Sidebar = ({ section }: Section) => {
  const theme = useSelector((state: State) => state.theme?.default);
  return (
    <motion.nav className={`sidebar sidebar__${section}`} {...ANIMATION_TRANSLATE_FROM_TOP} role="navigation">
      {Router.map(
        ({ isNavHome, name }) =>
          isNavHome && (
            <Link key={uuidv4()} {...SIDEBAR_OPTIONS} to={name.toLowerCase()} className="sidebar__item">
              <p className={`sidebar__title theme--${theme}`}>{name.toUpperCase()}</p>
              <span className={`sidebar__pin theme--${theme}`}></span>
            </Link>
          )
      )}
    </motion.nav>
  );
};

export default Sidebar;
