//~ Loader
export const ANIMATION_LOADER = {
  key: 'loader',
  initial: { opacity: 0, scale: 1 },
  animate: { opacity: 1 },
  transition: { duration: 0.7, ease: 'easeOut' },
  exit: { opacity: 0 },
};

export const ANIMATION_BACKGROUND = {
  key: 'backgroundLoader',
  initial: { opacity: 0, scale: 1 },
  animate: { opacity: 1 },
  transition: { duration: 1, ease: 'ease' },
  exit: { opacity: 0 },
};

export const ANIMATION_LAYOUT = {
  key: 'layoutLoader',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.7, ease: 'easeOut' },
  exit: { opacity: 1 },
};

//~ Logo
export const ANIMATION_LOGO_PARENT = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.3,
    },
  },
};

export const ANIMATION_LOGO_CHILDREN = {
  hidden: {
    y: -400,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      cubic: [0.6, 0.01, -0.05, 0.95],
      duration: 1.5,
    },
  },
};

//~ Animations on Page
export const ANIMATION_ONENTER_AND_ONLEAVE = {
  initial: {
    opacity: 0,
    y: -window.innerHeight,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 2, delay: 1.5 },
  },
  exit: {
    y: window.innerHeight,
    transition: { duration: 2 },
  },
};

export const ANIMATION_ONLEAVE = {
  exit: {
    opacity:0,
    transition: { duration: 1 },
  },
};

export const ANIMATION_TRANSLATE_DOWN = {
  initial: {
    opacity: 0,
    translateY: -50,
  },
  whileInView: {
    opacity: 1,
    transition: { duration: 1 },
    translateY: 0,
  },
};

export const ANIMATION_TRANSLATE_FROM_LEFT_INVIEW = {
  initial: {
    opacity: 0,
    translateX: '-100px',
  },
  whileInView: {
    opacity: 1,
    transition: { duration: 1 },
    translateX: 0,
  },
};
export const ANIMATION_TRANSLATE_FROM_LEFT_INVIEW_VERTICAL = {
  initial: {
    opacity: 0,
    translateX: '-100px',
    rotate: '90deg',
  },
  whileInView: {
    opacity: 1,
    transition: { duration: 1 },
    translateX: 0,
  },
};

export const ANIMATION_TRANSLATE_FROM_RIGHT_INVIEW = {
  initial: {
    opacity: 0,
    translateX: 100,
  },
  whileInView: {
    opacity: 1,
    transition: { duration: 1 },
    translateX: 0,
  },
};

export const ANIMATION_OPACITY_INVIEW = {
  initial: {
    opacity: 0,
  },
  whileInView: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

export const ANIMATION_TRANSLATE_FROM_RIGHT = {
  initial: {
    opacity: 0,
    translateX: '100px',
  },
  animate: {
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 1,
      delay: 3,
      type: 'spring',
      bounce: 0.4,
    },
  },
  exit: {
    translateX: '100px',
    transition: { delay: 0.5, duration: 1 },
  },
};

export const ANIMATION_TRANSLATE_FROM_LEFT = {
  initial: {
    opacity: 0,
    translateX: '-50px',
  },
  animate: {
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 1,
      delay: 3,
      type: 'spring',
      bounce: 0.4,
    },
  },
  exit: {
    translateX: '-100px',
    transition: { delay: 0.5, duration: 1 },
  },
};

export const ANIMATION_TRANSLATE_FROM_TOP = {
  initial: {
    opacity: 0,
    translateY: '-50px',
  },
  animate: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 1,
      delay: 3,
      type: 'spring',
      bounce: 0.4,
    },
  },
  exit: {
    translateY: '-100px',
    transition: { delay: 0.5, duration: 1 },
  },
};

export const ANIMATION_OPACITY = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 3,
      delay: 2,
    },
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.5, duration: 1 },
  },
};

export const ANIMATION_SCALE_UP = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  whileInView: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      scale: {
        type: 'spring',
        damping: 13,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  },
};

export const ANIMATION_SCALE_UP_SPRING = {
  initial: {
    scale: 0,
  },
  whileInView: {
    scale: 1,
    transition: {
      duration: 8,
      delay: 0.3,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  },
};
