//~ Import Modules
import './Navbar.scss';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

//~ Import Routes
import { Router } from '../../Routes/Routes';
import { State } from '../../../Types/GlobalTypes';

const Navbar = () => {
  const theme = useSelector((state: State) => state.theme?.default);

  return (
    <nav className={`navbar theme--${theme}`}>
      <ul className="navbar__items">
        {Router.map(
          ({ id, isNav, name, mainPath }) =>
            isNav && (
              <li key={id}>
                <NavLink className={({ isActive }) => (isActive ? 'navbar__item navbar__item--active' : 'navbar__item')} to={mainPath}>
                  {name}
                </NavLink>
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
