//~ Import module
import './Hexa.scss';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as HexaSVG } from './Hexa.svg';
import { Section } from '../../../Types/GlobalTypes';

//~ Component
const Hexa = ({ section }: Section) => {
  return (
    <div className={`hexa hexa__${section}`}>
      <HexaSVG className={`hexa__svg`} key={uuidv4()} />
    </div>
  );
};

export default Hexa;
