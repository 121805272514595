//~ Import module
import './Cloud.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ReactComponent as CloudSVG } from './cloud.svg';
import { State, Section } from '../../../Types/GlobalTypes';

//~ Component
const Cloud = ({ section }: Section) => {
  const theme = useSelector((state: State) => state.theme?.default);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end end'],
  });
  const y = useTransform(scrollYProgress, [0, 1], [0, 400]);

  return (
    <motion.div className={`cloud cloud__${section}`} ref={ref} style={{ y }}>
      <CloudSVG className={`cloud__svg theme--${theme}`} key={uuidv4()} data-parallax-axis="0.02" />
    </motion.div>
  );
};

export default Cloud;
