export default [
  {
    section: 'back',
    title: 'Back End',
    description: "― Back End Developer.",
    content:
      'Delivering Back End solutions and maintaining the server side of websites and web applications, I have a strong commitment to writing clean, maintainable and well-structured code that is efficient, maintainable and easy to understand.',
  },
  {
    section: 'front',
    title: 'Front End',
    description: "― Front End Developer.",
    content:
    " I create and deliver web applications, paying close attention to the nuances of design, optimisation and performance. I'm detail-oriented and giving close attention to understanding web accessibility standards and guidelines.",
  },
  {
    section: 'devops',
    title: 'DevOps',
    description: '― DevOps Tools.',
    content:
      'Using DevOps tools to streamline the development, deployment and maintenance of web applications, I understand the importance of automating the deployment process and using continuous integration and delivery (CI/CD) tools to ensure that code changes are tested and deployed quickly and efficiently.',
  },
  {
    section: 'design',
    title: 'Design',
    description: '― Creative Design.',
    content:
      'I am passionate about every aspect of the design process and am always looking for new and innovative ways to push the boundaries of what is possible can help you take your message to the next level, and communicating the desired message. I can help you achieve your goals and bring your vision to life.',
  },
];
