//~ Import module
import './Portrait.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'framer-motion';
import PictureBW from './yumi-bw.png';
import { ReactComponent as Frame } from './frame.svg';
import { State } from '../../../Types/GlobalTypes';

const Portrait = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const pictureRef = useRef(null);
  const isInView = useInView(pictureRef);

  return (
    <div ref={pictureRef} className="mask-container">
      <Frame key={uuidv4()} className={`picture__frame ${isInView ? 'picture__frame--animation' : ''} theme--${theme}`} />
      <div className={`picture picture__colored ${isInView ? 'picture__colored--animation' : ''}`} />
      <img src={PictureBW} className="picture picture__bw" alt="picture" />
    </div>
  );
};

export default Portrait;
