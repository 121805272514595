//~ Import module
import './SkillBadge.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as BadgeSkillSVG } from './SVG/badge-skillsgraph.svg';
import { Section } from '../../../Types/GlobalTypes';

//~ Component
const SkillBadge = ({ section }: Section) => {
  return (
    <Link className={`badge badge__${section}`} to={`/skillsgraph`}>
      <BadgeSkillSVG className={`badge__svg `} />
    </Link>
  );
};

export default SkillBadge;
