//~ Import module
import './Style/main.scss';
import * as imageSVG from './badgesSVG';
import { IBadge } from './Types';

//~ Component
const Badges = ({ badge }: IBadge) => {
  const imgSource = imageSVG[badge.name as keyof typeof imageSVG];

  return (
    <a href={badge.link} className="badge__link" target="_blank">
      <img className="badge__image" src={imgSource} alt={`badge ${badge.name}`} />
    </a>
  );
};

export default Badges;
