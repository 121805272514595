//~ Import module
import './Loader.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as LoaderSVG } from './Loader.svg';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Loader = () => {
  const theme = useSelector((state: State) => state.theme?.default);

  return (
    <div className="loader__container">
      <LoaderSVG className={`loader__svg theme--${theme}`} />
    </div>
  );
};

export default Loader;
