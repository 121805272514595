//~ Import modules
import './Dots.scss';
import { Section } from '../../../Types/GlobalTypes';

//~ Component
const Dots = ({ section }: Section) => {
  return <div className={`dots dots__${section}`}></div>;
};

export default Dots;
