//~ Import module
import './Binary.scss';
import { v4 as uuidv4 } from 'uuid';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ReactComponent as BinarySVG } from './binary.svg';
import { State, Section } from '../../../Types/GlobalTypes';

//~ Component
const Binary: FC<Section> = ({ section }) => {
  const theme = useSelector((state: State) => state.theme?.default);

  return (
    <motion.div className={`binary binary__${section}`}>
      <BinarySVG className={`binary__svg theme--${theme}`} key={uuidv4()} data-parallax-axis="0.005" />
    </motion.div>
  );
};

export default Binary;
