//~ Import modules
import { v4 as uuidv4 } from 'uuid';
import { SliderDotsProps } from './Types';

//~ Component
const SliderDots = ({ activeIndex, onclick, sliderText }: SliderDotsProps) => {
  return (
    <div className="slider__all-dots">
      {sliderText.map((slide: unknown, index: number) => (
        <span
          key={uuidv4()}
          className={`${activeIndex === index ? 'slider__dot slider__active-dot' : 'slider__dot'}`}
          onClick={() => onclick(index)}
        ></span>
      ))}
    </div>
  );
};

export default SliderDots;
