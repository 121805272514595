//~ Import module
import './RedHexa.scss';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';
import { ReactComponent as RedHexaSVG } from './RedHexa.svg';
import { RedHexaProps } from './Types';

//~ Component
const RedHexa = ({ number, section, size, direction }: RedHexaProps) => {
  const NUMBER_OF_REDHEXA = number;
  const items = [];

  for (let hexa = 0; hexa < NUMBER_OF_REDHEXA; hexa++) {
    items.push(<RedHexaSVG key={uuidv4()} />);
  }

  return (
    <div className={`red-hexa ${direction ? direction : ''} ${section ? 'red-hexa__' + section : ''}`}>
      {items.map((item) => (
        <motion.div className={`red-hexaSVG ${size ? size : ''} ${direction ? direction : ''}`} whileTap={{ scale: 1.2 }} key={uuidv4()}>
          {item}
        </motion.div>
      ))}
    </div>
  );
};

export default RedHexa;
