//~ Import module
import './Logo.scss';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ANIMATION_LOGO_CHILDREN, ANIMATION_LOGO_PARENT } from 'Utils/Animations/FramerMotion';
import { ReactComponent as LogoSVG } from './Logo.svg';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Logo = () => {
  const theme = useSelector((state: State) => state.theme?.default);

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToTop = (event: Event) => {
    event.preventDefault();
    location.pathname !== '/'
      ? navigate('/')
      : window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
  };

  const WORD_TO_ANIMATE = location.pathname === '/' ? 'YUMI.' : 'HOME';

  return (
    <div className="logo noselect">
      <motion.div className="logo__letters" variants={ANIMATION_LOGO_PARENT} initial="hidden" animate="show">
        {[...WORD_TO_ANIMATE].map((letter) => (
          <motion.span className={`logo__letter theme--${theme}`} key={uuidv4()} variants={ANIMATION_LOGO_CHILDREN}>
            {letter}
          </motion.span>
        ))}

        <motion.div variants={ANIMATION_LOGO_CHILDREN}>
          <LogoSVG onClick={scrollToTop} className={`logo__svg theme--${theme}`} />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Logo;
