//~ Import modules
import { createSlice } from '@reduxjs/toolkit';

//* Default variables
const DESKTOP_MIN_SIZE = 980;
const MOBILE_MAX_SIZE = 480;

//~ Initial state
const initialState = {
  defaultMediaScreen: window.innerWidth >= DESKTOP_MIN_SIZE ? 'desktop' : window.innerWidth <= MOBILE_MAX_SIZE ? 'mobile' : 'tablet',
  width: window.innerWidth - window.innerWidth * 0.1,
};

//~ Exports
export const mediaSlice = createSlice({
  name: 'mediaSlice',
  initialState,
  reducers: {
    //~ Methods
    setTargetScreen: (state) => {
      const isDesktopMedia = window.innerWidth >= DESKTOP_MIN_SIZE;
      const isTabletMedia = window.innerWidth > MOBILE_MAX_SIZE && window.innerWidth < DESKTOP_MIN_SIZE;
      const isMobileMedia = window.innerWidth <= MOBILE_MAX_SIZE;

      if (isDesktopMedia) state.defaultMediaScreen = 'desktop';
      if (isTabletMedia) state.defaultMediaScreen = 'tablet';
      if (isMobileMedia) state.defaultMediaScreen = 'mobile';
    },
    setInnerWidth: (state) => {
      state.width = window.innerWidth;
    },
  },
});

export const { setTargetScreen, setInnerWidth } = mediaSlice.actions;

export default mediaSlice.reducer;

// Default Size
/* 
  - Extra large screens, TV (XL): >= 1201px
  - Desktops, large screens (desktop): 1025px -  1200px
  - Small screens, laptops (laptop): 769px  - 1024px
  - iPads, Tablets (tablet): 481px - 768px
  - Mobile devices (mobile): 320px  -  480px
*/
