//~ Import module
import './Style/main.scss';
import { useSelector } from 'react-redux';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const ButtonWrapper = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  return (
    <button className={`button-wrapper theme--${theme}`}>
      <span className={`button-wrapper--span-1`}>
        <span className={`button-wrapper--span-2`}>
          <span className={`button-wrapper--span-3`} data-attr-span="View project">
            View project
          </span>
        </span>
      </span>
    </button>
  );
};

export default ButtonWrapper;
