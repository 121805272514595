//~ Import module
import './Category.scss';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import * as imageSVG from './categoriesSVG';
import { ANIMATION_SCALE_UP_SPRING } from 'Utils/Animations/FramerMotion';
import { ICategory } from './Types';

//~ Component
const Category = ({ category } : ICategory) => {
  const imgSource = imageSVG[category.name as keyof typeof imageSVG];
  return (
    <motion.a className={`category__link`} href={category.link} target="_blank" {...ANIMATION_SCALE_UP_SPRING}>
      <img className={`category__img`} src={`${imgSource}`} alt="reference project" />
    </motion.a>
  );
};

export default Category;
