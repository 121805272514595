//~ Import modules
import './HexaGrid.scss';
import { ReactComponent as HexaGridSVG } from './hexa-grid.svg';
import { Section } from '../../../Types/GlobalTypes';

//~ Component
const HexaGrid = ({ section }: Section) => {
  return (
    <section className={`hexa-grid hexa-grid__${section}`}>
      <HexaGridSVG className={`hexa-grid__svg`} />
    </section>
  );
};

export default HexaGrid;
