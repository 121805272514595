//~ Import modules
import './Graph.scss';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ForceGraph2D from 'react-force-graph-2d';
import graphData from './graphData.json';
import * as imageSVG from './SVG';
import { State } from '../../../Types/GlobalTypes';
import { Color } from './Types';

//~ Component
const Graph = () => {
  const color: Color | undefined = useSelector((state: State) => state.theme?.palette);
  const theme = useSelector((state: State) => state.theme?.default);
  const width = useSelector((state: State) => state.media?.width);

  const refGraph: any = useRef();

  // Handle immediate effects
  useEffect(() => {
    refGraph.current.d3Force('charge').strength(-150);
    refGraph.current.d3Force('charge').distanceMax((link: any) => link.target.distance_max);
    refGraph.current.d3Force('link').distance((link: any) => link.target.distance);
  });

  const data = {
    nodes: graphData.nodes,
    links: graphData.links.map((link) => ({ ...link, color: theme === 'dark' ? color?.white : color?.black })),
  };

  const onNodeClick = (node: any, event: Event) => {
    refGraph.current.centerAt(node.x, node.y, 2000);
    node.id === 1 ? refGraph.current.zoom(2, 2000) : refGraph.current.zoom(4, 2000);
  };

  const onNodeDrag = (node: any) => {
    // node.fx = node.x;
    // node.fy = node.y;
  };

  const onNodeDragEnd = (node: any) => {
    // node.fx = node.x;
    // node.fy = node.y;

    refGraph.current.centerAt(node.x, node.y, 1800);

    node.section === 'center' ? refGraph.current.zoom(2, 2000) : refGraph.current.zoom(4, 2000);
  };

  const onEngineStop = () => {
    refGraph.current.zoomToFit(1500, 170);
  };

  const drawSVG = (node: any, imageSrc: string, ctx: any) => {
    const { x, y, gapPosX, gapPosY, textX, textY } = node;

    const blockElement = new Image();

    blockElement.src = imageSrc;

    ctx.drawImage(blockElement, x + gapPosX, y + gapPosY);
  };

  const nodeCanvasObject = (node: any, ctx: any) => {
    const { center, frag_back, frag_front, frag_devops, frag_design } = imageSVG;

    const { id, x, y } = node;

    if (node.section === 'center') drawSVG({ ...node }, center, ctx);
    if (node.section === 'back') drawSVG({ ...node }, frag_back, ctx);
    if (node.section === 'front') drawSVG({ ...node }, frag_front, ctx);
    if (node.section === 'devops') drawSVG({ ...node }, frag_devops, ctx);
    if (node.section === 'design') drawSVG({ ...node }, frag_design, ctx);

    const isNodeCenter = id === 1;

    // Outline
    ctx.beginPath();

    ctx.arc(x, y, isNodeCenter ? 5 : 3, 0, 2 * Math.PI, false);
    ctx.lineWidth = isNodeCenter ? 0.5 : 0.2;
    ctx.fillStyle = color?.red;
    ctx.strokeStyle = color?.red;
    ctx.shadowColor = color?.shadow;
    ctx.shadowOffsetY = 8;
    ctx.shadowOffsetX = 5;
    ctx.shadowBlur = 15;
    ctx.stroke();

    // Inner circle
    ctx.beginPath();

    ctx.arc(x, y, isNodeCenter ? 4 : 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = color?.red;
    ctx.strokeStyle = color?.red;
    ctx.fill();
  };

  const options = {
    // Main option
    graphData: data,
    // Not required
    ref: refGraph,
    enableZoomInteraction: false,
    // enableNavigationControls: false,
    // enablePanInteraction: false,
    width: width,
    maxZoom: 3,
    minZoom: 2,
    // When engine start
    cooldownTicks: 30,
    // Method applied on nodes
    onEngineStop,
    onNodeClick,
    // onNodeDrag,
    // onNodeDragEnd,
    // Draw the object
    nodeCanvasObject,
  };

  return (
    <div id="graph-container" className="container-boxes" aria-label="graph of techno">
      <ForceGraph2D {...options} />
    </div>
  );
};

export default Graph;
