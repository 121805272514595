//~ Import module
import './Spinner.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as SpinnerSVG } from './Spinner.svg';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Spinner = () => {
  const theme = useSelector((state: State) => state.theme?.default);

  return (
    <div className="spinner__container">
      <SpinnerSVG className={`spinner__svg theme--${theme}`} />
    </div>
  );
};

export default Spinner;
