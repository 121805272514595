//~ Import modules
import './Style/main.scss';
import { SetStateAction, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, useScroll, useTransform } from 'framer-motion';
import SliderDots from './SliderDots';
import SliderContent from './SliderContent';
import sliderText from './SliderText';
import { SkillBadge } from '../';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Slider = () => {
  const defaultMediaScreen = useSelector((state: State) => state.media?.defaultMediaScreen);
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start start', 'end end'] });
  const opacity1 = useTransform(scrollYProgress, [0, 1], [0, 1.5]);
  const opacity2 = useTransform(scrollYProgress, [0, 0.3], [0.8, 1]);
  const length = sliderText.length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === length ? 0 : activeIndex + 1);
    }, 7000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <motion.div className="slider__container" ref={ref} style={{ opacity: defaultMediaScreen === 'desktop' ? opacity1 : 1 }}>
      <SliderDots activeIndex={activeIndex} sliderText={sliderText} onclick={(activeIndex: SetStateAction<number>) => setActiveIndex(activeIndex)} />

      <SliderContent activeIndex={activeIndex} sliderText={sliderText} />

      <SkillBadge section="skills" />
    </motion.div>
  );
};

export default Slider;
