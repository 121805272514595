//& Import modules
import './Card.scss';
import { v4 as uuidv4 } from 'uuid';
import { ICard } from './Types';

//~ Component
const Card = ({ card }: ICard) => {
  return (
    <article className="card">
      <p key={uuidv4()} className="card__content">
        {card?.paragraph}
      </p>
    </article>
  );
};

export default Card;
