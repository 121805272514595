import { createSlice } from '@reduxjs/toolkit';

//* Initial state
const initialState = {
  x: 0,
  y: 0,
};

//~ Exports
export const cursorSlice = createSlice({
  name: 'cursorSlice',
  initialState,

  reducers: {
    mousePosition: (state, action) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
    },

    parallaxEffectOnMouseMove: (state) => {
      const transformAxisElement = (dataElements: any, xAxis: string, yAxis: string | null) => {
        for (let i = 0; i < dataElements.length; i++) {
          yAxis === null && (yAxis = xAxis);
          const xAxisValue: number | undefined = +dataElements[i].dataset[`${xAxis}`];
          const yAxisValue: number | undefined = +dataElements[i].dataset[`${yAxis}`];
          let x = state.x * xAxisValue;
          let y = state.y * yAxisValue;
          dataElements[i].style.transform = `translate(${x}px, ${y}px)`;
        }
      };

      const allDataAxis = Array.from(document.querySelectorAll(`[data-parallax-axis]`) as NodeListOf<Element>);
      const allDataXAxis = Array.from(document.querySelectorAll(`[data-parallax-x-axis]`) as NodeListOf<Element>);
      const allDataYAxis = Array.from(document.querySelectorAll(`[data-parallax-y-axis]`) as NodeListOf<Element>);

      if (allDataAxis) transformAxisElement(allDataAxis, 'parallaxAxis', null);
      if (allDataXAxis && allDataYAxis) transformAxisElement(allDataXAxis, 'parallaxXAxis', 'parallaxYAxis');
    },

    
  },
});

export const { mousePosition, parallaxEffectOnMouseMove } = cursorSlice.actions;
export default cursorSlice.reducer;
